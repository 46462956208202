import React from "react";
import { Box, Tab, Tabs, TabList, TabPanel, TabPanels } from "@chakra-ui/react";
function Tabs9() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleSliderChange = (event) => {
    setTabIndex(parseInt(event.target.value, 10));
  };
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  return (
    <Box>
      <input
        type="range"
        min="0"
        max="12"
        value={tabIndex}
        onChange={handleSliderChange}
      />
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab> One </Tab>
          <Tab> Two </Tab>
          <Tab> Three </Tab>
          <Tab> Four </Tab>
          <Tab> Five </Tab>
          <Tab> Six </Tab>
          <Tab> Seven </Tab>
          <Tab> Eight </Tab>
          <Tab> Nine </Tab>
          <Tab> Ten </Tab>
          <Tab> Eleven </Tab>
          <Tab> Twelve </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <p> Click the tabs or pull the slider around </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 2 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 3 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 4 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 5 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 6 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 7 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 8 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 9 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 10 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 11 </p>
          </TabPanel>
          <TabPanel>
            <p> LexDAO Breakthrough 12 </p>
          </TabPanel>
          <TabPanel>
            <p>
              {" "}
              Nine LexDAO provides value and service supporting the fourth
              industrial revolution. Web3 is a set of technologies that publicly
              interoperate through blockchain and node based computing systems.{" "}
            </p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
export default Tabs9;
