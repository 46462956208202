import React from "react";
import {
  Box,
  Image,
  Text,
  Link,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  Stack,
  Button,
} from "@chakra-ui/react";
function FormLayout13() {
  const [value, setValue] = React.useState("1.00");
  const handleChange = (value) => setValue(value);
  const format = (val) => `$` + val;
  const parse = (val) => val.replace(/^\$/, "");
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 40 }}
          src=" https://bafybeiehzlovrv4rtpxvcu42zjhgfltlnoqfdl3rfbz37khfayxtpxqeei.ipfs.infura-ipfs.io/
 "
          alt=" LexDAO "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="sm"
          letterSpacing="wide"
          color="yellow.600"
        >
          SmartLaw Web3Skills Shopping Cart
        </Text>

        <br />
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Select the Course Number
        </Link>
        <Stack shouldWrapChildren direction="row">
          <NumberInput size="md" maxW={24} defaultValue={15} min={10}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Stack>
      

        <br />
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Select the Quantity of Seats
        </Link>
          <NumberInput maxW="120px"
            allowMouseWheel max={100} min={0}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

        <br />
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Select the Transaction Amount in $ USD.
        </Link>
        <NumberInput
          w="120px"
          onChange={(valueString) => setValue(parse(valueString))}
          value={format(value)}
          max={100}
          min={0}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <br />
        <Flex>
          <NumberInput
            maxW="100px"
            mr="2rem"
            value={value}
            onChange={handleChange}
            max={100}
            min={0}
          >
            <NumberInputField max={100} min={0} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

          <Slider
            flex="1"
            focusThumbOnChange={false}
            value={value}
            onChange={handleChange}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb fontSize="sm" boxSize="32px" children={value} />
          </Slider>
        </Flex>

        <Text mt={2} color="gray.500">
          Getting a new organization off the ground takes patience. Here are
          some ways LexDAO members are found.
        </Text>
        <Button colorScheme="blue" size="sm">
          Submit
        </Button>
      </Box>
    </Box>
  );
}
export default FormLayout13;
