import React from "react";
import {
  Box,
  Image,
  Text,
  //  Link,
  //  Editable,
  //  EditableInput,
  //  EditablePreview,
  //  Skeleton,
  //  SkeletonCircle,
  //  SkeletonText,
  Spinner,
  Progress,
  Stack,
} from "@chakra-ui/react";
function FeedbackLayout2() {
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 48 }}
          src=" https://bafybeiehzlovrv4rtpxvcu42zjhgfltlnoqfdl3rfbz37khfayxtpxqeei.ipfs.infura-ipfs.io/
 "
          alt=" LexDAO "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>

        <br />

        <Stack spacing={5}>
          <Progress size="xs" colorScheme="green" isIndeterminate />
          <Progress size="xs" colorScheme="red" isIndeterminate />
          <Progress size="xs" colorScheme="yellow" isIndeterminate />
          <Progress size="xs" colorScheme="cyan" isIndeterminate />
          <Progress size="xs" colorScheme="orange" isIndeterminate />
          <Progress size="xs" colorScheme="blue" isIndeterminate />
          <Progress size="xs" colorScheme="purple" isIndeterminate />
          <Progress size="xs" colorScheme="pink" isIndeterminate />
        </Stack>

        <Text mt={2} color="gray.500">
          Getting a new organization off the ground takes patience. Here are
          some ways LexDAO members are found.
        </Text>

        <br />
        <br />
        <Stack direction="row" spacing={4}>
          <Spinner size="xs" color="red.500" />
          <Spinner size="xs" color="pink.500" />
          <Spinner size="xs" color="blue.500" />
          <Spinner size="xs" color="purple.500" />
          <Spinner size="xs" />
          <Spinner size="sm" />
          <Spinner size="md" />
          <Spinner size="lg" />
          <Spinner size="xl" />


        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="green.500"
          size="xl"
        />
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="red.500"
          size="xl"
        />
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="yellow.500"
          size="xl"
        />
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="cyan.500"
          size="xl"
        />
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="orange.500"
          size="xl"
        />
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="purple.500"
          size="xl"
        />
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="pink.500"
          size="xl"
        />
                </Stack>
      </Box>
    </Box>
  );
}
export default FeedbackLayout2;
