import React from "react";
import {
  Box,
  Image,
  Text,
  Link,
  Checkbox,
  CheckboxGroup,
  Stack,
  HStack,
} from "@chakra-ui/react";
function FormLayout1() {
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 40 }}
          src=" https://bafybeiehzlovrv4rtpxvcu42zjhgfltlnoqfdl3rfbz37khfayxtpxqeei.ipfs.infura-ipfs.io/
 "
          alt=" LexDAO "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="sm"
          letterSpacing="wide"
          color="white"
        >
          <Checkbox
            defaultIsChecked
            spacing="1rem"
            iconColor="blue.900"
            iconSize="1rem"
          >
            Checkbox
          </Checkbox>
          <br />
          <br />
          <Stack spacing={10} direction="row">
            <Checkbox
              isDisabled
              spacing="1rem"
              iconColor="blue.900"
              iconSize="1rem"
            >
              Checkbox
            </Checkbox>
            <Checkbox
              isDisabled
              defaultIsChecked
              spacing="1rem"
              iconColor="blue.900"
              iconSize="1rem"
            >
              Checkbox
            </Checkbox>
          </Stack>
          <br />
          <Stack spacing={10} direction="row">
            <Checkbox
              colorScheme="blue"
              spacing="1rem"
              iconColor="blue.900"
              iconSize="1rem"
              defaultIsChecked
            >
              Checkbox
            </Checkbox>
            <Checkbox
              colorScheme="orange"
              spacing="1rem"
              iconColor="blue.900"
              iconSize="1rem"
              defaultIsChecked
            >
              Checkbox
            </Checkbox>
          </Stack>
          <br />
          <HStack spacing={10} direction="row">
            <Checkbox
              size="sm"
              colorScheme="red"
              spacing="1rem"
              iconColor="white"
              iconSize="1rem"
            >
              Checkbox
            </Checkbox>
            <Checkbox
              size="md"
              colorScheme="green"
              spacing="1rem"
              iconColor="blue.900"
              iconSize="1rem"
              defaultIsChecked
            >
              Checkbox
            </Checkbox>
            <Checkbox
              size="lg"
              colorScheme="orange"
              spacing="1rem"
              iconColor="blue.900"
              iconSize="1rem"
              defaultIsChecked
            >
              Checkbox
            </Checkbox>
          </HStack>
          <br />
          <CheckboxGroup
            colorScheme="orange"
            defaultValue={["option1", "option3"]}
          >
            <HStack>
              <Checkbox value="option1">Multi-Choice 1</Checkbox>
              <Checkbox value="option2">Multi-Choice 2</Checkbox>
              <Checkbox value="option3">Multi-Choice 3</Checkbox>
            </HStack>
          </CheckboxGroup>
          <br />
          <br />
          <br />
          <br />
        </Text>
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Finding Members and Patrons for LexDAO
        </Link>
        <Text mt={2} color="gray.500">
          Getting a new organization off the ground takes patience. Here are
          some ways LexDAO members are found.
        </Text>
      </Box>
    </Box>
  );
}
export default FormLayout1;
