import React from "react";
import { Box, Image, Text, Link, Stack, Input, Button } from "@chakra-ui/react";
function FormLayout5() {
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 40 }}
          src=" https://bafybeiehzlovrv4rtpxvcu42zjhgfltlnoqfdl3rfbz37khfayxtpxqeei.ipfs.infura-ipfs.io/
 "
          alt=" LexDAO "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="sm"
          letterSpacing="wide"
          color="teal.600"
        >
          LexDAO
        </Text>
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Top Web3Skills you would like to learn
        </Link>
        <Stack spacing={3}>
          <Input
            focusBorderColor="lime"
            placeholder=" Enter the answer here "
          />
          <Input
            focusBorderColor="pink.400"
            placeholder=" Enter the answer here "
          />
          <Input
            isInvalid
            errorBorderColor="red.300"
            placeholder=" Enter the answer here "
          />
          <Input
            isInvalid
            errorBorderColor="crimson"
            placeholder=" Enter the answer here "
          />
        </Stack>
        <Text mt={2} color="gray.500">
          Getting a new organization off the ground takes patience. Here are
          some ways LexDAO members are found.
        </Text>
        <br />
        <Button colorScheme="messenger" size="md" type="submit">
          Submit
        </Button>
      </Box>
    </Box>
  );
}
export default FormLayout5;
