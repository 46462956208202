import React from "react";
import {
  Box,
  Image,
  Text,
  Link,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
} from "@chakra-ui/react";
function FormLayout11() {
  const format = (val) => `$` + val;
  const parse = (val) => val.replace(/^\$/, "");
  const [value, setValue] = React.useState("1.00");
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 40 }}
          src=" https://bafybeiehzlovrv4rtpxvcu42zjhgfltlnoqfdl3rfbz37khfayxtpxqeei.ipfs.infura-ipfs.io/
 "
          alt=" LexDAO "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 8 }}>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="sm"
          letterSpacing="wide"
          color="yellow"
        >
          Web3Skills Shopping Cart
        </Text>
        <br />


        
        

    
        <br />
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Select the total quantity.
        </Link>
        
        
        <NumberInput
          step={5}
          defaultValue={15}
          min={0}
          max={100}
          precision={2}
          w="200px"
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <br />
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Select the unit price.
        </Link>
        <NumberInput
          w="200px"
          onChange={(valueString) => setValue(parse(valueString))}
          value={format(value)}
          max={100}
          min={0}
        >
        
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <br />
        


        <Text mt={2} color="gray.500">
          Getting a new organization off the ground takes patience. Here are
          some ways LexDAO members are found.
        </Text>
        <Button colorScheme="blue" size="sm">
          Submit
        </Button>
      </Box>
    </Box>
  );
}
export default FormLayout11;
