import React from "react";
import {
  Box,
  Text,
  Link,
  Editable,
  EditableInput,
  EditablePreview,
} from "@chakra-ui/react";
function FormLayout17() {
  return (
    <Box p={4} display={{ md: "flex" }}>

      <Box mt={{ base: 4, md: 0 }} ml={{ md: 48 }}>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="sm"
          letterSpacing="wide"
          color="yellow.600"
        >
          Send a Transaction to a Blockchain
        </Text>
        <Editable defaultValue=" Type a test message to send with the transaction here:">
          <EditablePreview />
          <EditableInput />
        </Editable>
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Discover SmartLaw Web3Skills
        </Link>
        <Text mt={2} color="gray.500">
          Getting a new organization off the ground takes patience. Here are
          some ways LexDAO members are found.
        </Text>
      </Box>
    </Box>
  );
}
export default FormLayout17;
