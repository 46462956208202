import React from "react";
import { Box, Image, Text, Input, Stack, Button } from "@chakra-ui/react";
function FormLayout6() {
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 40 }}
          src=" https://bafybeiehzlovrv4rtpxvcu42zjhgfltlnoqfdl3rfbz37khfayxtpxqeei.ipfs.infura-ipfs.io/
 "
          alt=" LexDAO "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="sm"
          letterSpacing="wide"
          color="teal.600"
        >
          LexDAO Member Passcode
        </Text>
        <Stack spacing={3}>
          <Input placeholder="Mnemonic 1" size="md" />
          <Input placeholder="Mnemonic 2" size="md" />
          <Input placeholder="Mnemonic 3" size="md" />
        </Stack>

        <br />
        <Button colorScheme="messenger" size="md" type="submit">
          Submit
        </Button>
      </Box>
    </Box>
  );
}
export default FormLayout6;
