import React from "react";
import {
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Image,
} from "@chakra-ui/react";
function Tabs8() {
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Tabs defaultIndex={4}>
          <TabPanels>
            <TabPanel>
              <Image
                boxSize="300px"
                fit="cover"
                src="https://bafybeicniqau3l4z62cknoo3sv6p7yi6hpohzgmwbrxh4ulnberqpeyfjq.ipfs.infura-ipfs.io/"
                />
            </TabPanel>
            <TabPanel>
              <Image
                boxSize="200px"
                fit="cover"
                src=" https://bafybeif43rpfhv7j5szrbx54nt63yz3zd6a4f3qhddher6ehw7znwjlklq.ipfs.infura-ipfs.io/ "
              />
            </TabPanel>
            <TabPanel>
              <Image
                boxSize="200px"
                fit="cover"
                src=" https://bafybeihhbomu6ieygq6z4ml5gqtjca7diq6w3dlasco5h2vinaqliy3hh4.ipfs.infura-ipfs.io/ "
              />
            </TabPanel>
            <TabPanel>
              <Image
                boxSize="200px"
                fit="cover"
                src=" https://bafybeihrwecvzaihky6ugoi3wc6dqbgkey6vybuvjobtw6uzm2oypwbui4.ipfs.infura-ipfs.io/ "
              />
            </TabPanel>
            <TabPanel>
              <Image
                boxSize="200px"
                fit="cover"
                src=" https://bafybeiau244on3brme4xr2b4t423h2njqusigivz6zpsqg3mga5uqzwsxu.ipfs.infura-ipfs.io/ "
              />
            </TabPanel>
            <TabPanel>
              <Image
                boxSize="200px"
                fit="cover"
                src=" https://bafybeic4gshabcvjedg2iplbmrtf4a3kz4wamutgdlnarnpftfkuv67mcy.ipfs.infura-ipfs.io/ "
              />
            </TabPanel>
            <TabPanel>
              <Image
                boxSize="200px"
                fit="cover"
                src=" https://bafybeiau2dx63wrau3h36bsb5coiyyscwmeot2w3hdrjwyi6wh4vmhbriq.ipfs.infura-ipfs.io/ "
              />
            </TabPanel>
            <TabPanel>
              <Image
                boxSize="200px"
                fit="cover"
                src=" https://bafybeidpoeouvcxvlskm55ar57epbuhotzkh4l52bdwv34gfdnp73eqzfq.ipfs.infura-ipfs.io/ "
              />
            </TabPanel>
            <TabPanel>
              <Image
                boxSize="200px"
                fit="cover"
                src=" https://bafybeieg2fii6h2xtlrpfnwqbzoivjrxfeioz4gzyw34pi3ogtvohxgvaq.ipfs.infura-ipfs.io/ "
              />
            </TabPanel>
          </TabPanels>
          <TabList>
            <Tab> Developers Group </Tab>
            <Tab> Designers Group </Tab>
            <Tab> Documents Group </Tab>
            <Tab> Artists Group </Tab>
            <Tab> Policymakers Group </Tab>
            <Tab> Marketing Group </Tab>
            <Tab> Rewards Group </Tab>
            <Tab> Recruiters Group </Tab>
            <Tab> Enforcement Group </Tab>
          </TabList>
        </Tabs>
      </Box>
    </Box>
  );
}
export default Tabs8;
