import React, { useEffect } from "react";
import logo from "../../libs/images/web3skills-devJ-ltblue.png";
import "../../App.css";
import {
  Wrap,
  WrapItem,
  Stack,
  Spacer,
  // HStack,
  // VStack,
  Avatar,
  // AvatarBadge,
  // AvatarGroup,
  // Tooltip,
  // Icon,
  // Button
} from "@chakra-ui/react";

const d3 = require("d3");

function Donut(props) {
  const width = 932;
  const radius = width / 6;
  const format = d3.format(",d");
  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius((d) => d.y0 * radius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

  let data;

  const dataOriginal =
    !props.dataGraphed || props.dataGraphed.children.length === 0;
  if (dataOriginal) {
    data = props.data;
  } else {
    data = props.dataGraphed;
  }

  const color = d3.scaleOrdinal(
    d3.quantize(d3.interpolateRainbow, data.children.length + 1)
  ); // this kills the color palette in ipfs_API
  const partition = (data) => {
    const root = d3
      .hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);
    return d3.partition().size([2 * Math.PI, root.height + 1])(root);
  };

  let chart = () => {
    const root = partition(data);

    root.each((d) => (d.current = d));

    const svg = d3
      .select("#graph")
      .append("svg")
      .attr("viewBox", [0, 0, width, width])
      .style("font", "10px sans-serif");

    Object.entries(props.style ?? {}).forEach(([attr, val]) => {
      svg.style(attr, val);
    });

    const g = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${width / 2})`);

    const div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("color", "white");

    const path = g
      .append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", (d) => {
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
      )
      .attr("d", (d) => arc(d.current))
      .on("mouseover", (evt, d) => {
        div.transition().duration(200).style("opacity", 0.9);
        // div.html(`<span>name: ${evt.target.__data__.data.name}</span><br/><span>url: ${d.target.__data__.data.url}</span><br/><span>description: ${d.target.__data__.data.description}</span>`)
        // .style("right", "1px")
        // .style("top",  "1px");
      })
      .on("mouseout", function (d) {
        div.transition().duration(500).style("opacity", 0);
      });

    path
      .style("cursor", (d) => (Boolean(d.children) ? "crosshair" : "pointer"))
      .on("click", clicked);

    path.append("title").text(
      (d) =>
        `${d
          .ancestors()
          .map((d) => d.data.name)
          .reverse()
          .join("/")}\n${format(d.value)}`
    );

    const label = g
      .append("g")
      .attr("pointer-events", "all")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current))
      .style("fill", "white")
      .text((d) => d.data.name)
      .style("cursor", (d) => (Boolean(d.children) ? "crosshair" : "pointer"))
      .on("click", clicked)
      .on("mouseover", (evt) => {
        d3.select(evt.target)
          .transition()
          .duration(100)
          .style("font-size", "20px");
      })
      .on("mouseout", (evt) => {
        d3.select(evt.target)
          .transition()
          .duration(100)
          .style("font-size", "10px");
      });

    const parent = g
      .append("image")
      .attr("pointer-events", "all")
      .on("click", clicked)
      .attr("xlink:href", logo)
      .attr("width", 240)
      .attr("height", 160)
      .attr("x", -120)
      .attr("y", -80);

    function clicked(event, p) {
      const {
        data: { url },
      } = p;
      if (url) {
        return window.open(url, "_blank").focus();
      }
      if (!p) return;
      parent.datum(p.parent || root);
      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          })
      );

      const t = g.transition().duration(350);

      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", (d) =>
          arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attrTween("d", (d) => () => arc(d.current));

      label
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        })
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
    return svg.node();
  };

  useEffect(() => {
    d3.select("#graph").select("svg").remove();
    chart(); //useRef()?
  }, [props.dataGraphed, props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div
        id="graph"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "5vh auto",
        }}
      ></div>

      <Spacer />

      <Stack direction="row" spacing={2}>
        <Wrap>

        <WrapItem>
            <Avatar
              name="SmartLaw Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeicpmezqlugh55ifdoilw3c4eyjlbwiqgxri4wg5zhms2cfsiooaou.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Judiciary Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeifl4n2us4ndblnilw74fuk3h4ft6c7jxomtq4aqg3tpwrvic73gdq.ipfs.infura-ipfs.io/"
            />
          </WrapItem>


          <WrapItem>
            <Avatar
              name="Developers Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeicniqau3l4z62cknoo3sv6p7yi6hpohzgmwbrxh4ulnberqpeyfjq.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="State Change Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeiau244on3brme4xr2b4t423h2njqusigivz6zpsqg3mga5uqzwsxu.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Blockchain Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeibm4odxsivzo76e5qbhkn3kstb5j55oxcu6rayleak6kto2lmbvma.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Documents Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeihhbomu6ieygq6z4ml5gqtjca7diq6w3dlasco5h2vinaqliy3hh4.ipfs.infura-ipfs.io/"
            />
          </WrapItem>


          <WrapItem>
            <Avatar
              name="Strategies Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeigx6zdoig37fdxl42tcrpdp3kuphgyykzyxoxai3stzyg2ichovhu.ipfs.infura-ipfs.io/"
            />
          </WrapItem>


          <WrapItem>
            <Avatar
              name="Tea Leaves Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeigg756obzu2wynpi5szemw6eeuk7qbwkbzmlahdcjelsahidvmsle.ipfs.infura-ipfs.io/"
            />
          </WrapItem>



          <WrapItem>
            <Avatar
              name="Designers Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeif43rpfhv7j5szrbx54nt63yz3zd6a4f3qhddher6ehw7znwjlklq.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Artists Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeihrwecvzaihky6ugoi3wc6dqbgkey6vybuvjobtw6uzm2oypwbui4.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="AirDrop Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeifeoqyotdkycza6vj54ck4x43pvl55ov5md734sf6hds6522qlc7i.ipfs.infura-ipfs.io/"
            />
          </WrapItem>



          <WrapItem>
            <Avatar
              name="Advocates Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeiawkdoa3fv5h3jttv24hp4w67pqmi53i2tuxo7zxated3ypnpeh5u.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Marketing Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeic4gshabcvjedg2iplbmrtf4a3kz4wamutgdlnarnpftfkuv67mcy.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Recruiting Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeidpoeouvcxvlskm55ar57epbuhotzkh4l52bdwv34gfdnp73eqzfq.ipfs.infura-ipfs.io/"
            />
          </WrapItem>
          



          <WrapItem>
            <Avatar
              name="Procedures Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeifm3ewo4ajthgozpwqhhp3o4w3tpcw7jeo3ekdefiw7t4iovfdsoi.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Project Management Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeihz3cq6bbknvbt3idsnoek2zbf3l7skxkbltyhfdgg7nsljevatpm.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Skills Donut Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeig23sfptdttuemfrckxc7264txskcxigb5easudkvub35adtwcjpy.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Skills Tree Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeieqkrem2v2h3v2wlg2ojevlyhaomirj7bywg3qsgrmtou4oyqxqra.ipfs.infura-ipfs.io/"
            />
          </WrapItem>


          
          <WrapItem>
            <Avatar
              name="Rewards Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeiau2dx63wrau3h36bsb5coiyyscwmeot2w3hdrjwyi6wh4vmhbriq.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Enforcement Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeieg2fii6h2xtlrpfnwqbzoivjrxfeioz4gzyw34pi3ogtvohxgvaq.ipfs.infura-ipfs.io/"
            />
          </WrapItem>
          <WrapItem>
            <Avatar
              name="Evidencer Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeieqfrcmwbk3mhti622ahfxdtu2mxveiqv5qq4p62flo3ppj4w7oe4.ipfs.infura-ipfs.io/"
            />
          </WrapItem>

          <WrapItem>
            <Avatar
              name="Balancing Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeiep5nzotpnv44phwa54zp64zrgc6p5hxjyaq5f3u3fuvmaruh6xhm.ipfs.infura-ipfs.io/"
            />
          </WrapItem>


          <WrapItem>
            <Avatar
              name="Justice Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeihyza5nwoswu52kbl62cojco43rivmxcvyfdshisfcgeoanmjp4aa.ipfs.infura-ipfs.io/"
            />
          </WrapItem>


          

          <WrapItem>
            <Avatar
              name="Governance Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeicik2x6hwqlup4ph56ewuvg6tjbkrtvzievnzmrxt2bghmodjuq4u.ipfs.infura-ipfs.io/"
            />
          </WrapItem>
          <WrapItem>
            <Avatar
              name="Protectors Group"
              bg="gray.500"
              size="2xl"
              src="https://bafybeib6pk7ey6jkg2rm2qzap5sf36gecoxxqulcw5pb4c7wh5xnjbrye4.ipfs.infura-ipfs.io/"
            />
          </WrapItem>
        </Wrap>
      </Stack>
    </div>
  );
}

export default Donut;
