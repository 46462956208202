import React from "react";
import {
  Box,
  Image,
  Text,
  Link,
  PinInput,
  PinInputField,
  HStack,
  Button,
} from "@chakra-ui/react";
function FormLayout12() {
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 40 }}
          src=" https://bafybeiehzlovrv4rtpxvcu42zjhgfltlnoqfdl3rfbz37khfayxtpxqeei.ipfs.infura-ipfs.io/
 "
          alt=" LexDAO "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="sm"
          letterSpacing="wide"
          color="yellow"
        >
          Set a Web3Skills passcode
        </Text>
<br />
        <Link
          mt={1}
          color="red.400"
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Numbers Only Pincode
        </Link>
        <HStack>
          <PinInput size="md">
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
          <Button colorScheme="blue" size="sm">
            Submit
          </Button>
        </HStack>
        <br />
        <Link
          mt={1}
          color="red.300"
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Alphanumeric Passcode
        </Link>
        <HStack>
          <PinInput size="md" type="alphanumeric">
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
          <Button colorScheme="blue" size="sm">
            Submit
          </Button>
        </HStack>
        <br />
        <Link
          mt={1}
          color="red.200"
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Masked Passcode
        </Link>
        <HStack>
          <HStack>
            <PinInput size="md" type="alphanumeric" mask>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
            <Button colorScheme="blue" size="sm">
              Submit
            </Button>
          </HStack>
        </HStack>
        <Text mt={2} color="gray.500">
          Getting a new organization off the ground takes patience. Here are
          some ways LexDAO members are found.
        </Text>
      </Box>
    </Box>
  );
}
export default FormLayout12;
